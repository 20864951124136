import axios from "axios";
import React, { useEffect, useState } from "react";
import "./App.css";

function DataFetching() {
  const [tickets, setTickets] = useState([]);
  const config = {
    headers: {
      "access-control-allow-origin": "*",
    },
  };
  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get(
          "https://ppgco87lc1.execute-api.ap-southeast-2.amazonaws.com/Prod/api",
          config
        )
        .then((res) => {
          console.log(res);
          setTickets(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  });

  return (
    <div className="container">
      <div className="card-wrap">
        <div className="card">
          <h3>Total New</h3>
          <div className="container">
            <h4>{tickets[0] > 0 ? tickets[0] : "0"}</h4>
          </div>
        </div>
      </div>
      <div className="card-wrap">
        <div className={"card " + (tickets[1] > 0 ? "overTen" : "")}>
          <h3>Total Over 10 Mins</h3>
          <div className="container">
            <h4>{tickets[1] > 0 ? tickets[1] : "0"}</h4>
          </div>
        </div>
      </div>
    </div>
  );
}
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>New Tickets</h1>
        <DataFetching />
      </header>
    </div>
  );
}

export default App;
